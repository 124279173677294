<template>
  <div id="edit-salesforce-lookup" class="vap-page">
    <div id="edit-salesforce-lookup-header" class="vap-page-header">
      <h1 id="header">{{ lookupTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>

        <VasionButton
          class="last-btn"
          classProp="primary"
          :isDisabled="!isValid"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>

    <div id="main-section" class="vap-page-content">
      <div id="lookup-details">
        <div class="row">
          <VasionInput
            id="lookup-name"
            v-model="lookupName"
            class="row-field"
            title="LOOKUP NAME"
            inputType="top-white"
            name="lookup-name"
            placeholder="Enter Name..."
            :width="'400'"
            @input="markDirty()"
          />

          <VasionDropList
            v-slot="slotItem"
            v-model="vasionObject"
            class="row-field"
            :dataArray="attributeFormList"
            width="326"
            title="VASION LOOKUP OBJECT"
            placeholder="Select Object..."
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="markDirty()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div class="row">
          <VasionInput
            v-model="salesForceObject"
            class="row-field"
            width="400"
            title="SALESFORCE OBJECT"
            inputType="top-white"
            placeholder="Enter Object..."
            @input="markDirty()"
          />

          <VasionButton
            class="add-database-connection-icon-button"
            :isDisabled="disableSync"
            :classProp="'secondary-grey'"
            @vasionButtonClicked="syncLookup()"
          >
            <label>Sync</label>
          </VasionButton>
        </div>
        <div class="row">
          <!-- Default Lookup Checkbox Here -->
          <VasionCheckbox
            :id="'default-lookup-checkbox'"
            :checked="defaultLookup"
            @change="defaultLookup = !defaultLookup; markDirty()"
          >
            Default Lookup
          </VasionCheckbox>
        </div>

        <div class="row comment">
          *You must have at least one field selected as a search field. 
        </div>
        <div v-show="displaySalesForceTable" class="vasion-html-table-default paddingRight">
          <!-- Table of Fields, SalesForce Fields, and Search Here -->
          <VasionEditableTable
            ref="salesforceTable"
            :headerColumns="headerColumns"
            :tableRows="tableRows"
            @cellValueChanged="cellChanged"
          />
        </div>
      </div>
    </div>

    <VasionSnackbar
      id="database-lookup-snack"
      :showSnackbarBool.sync="showVasionSnackbar"
      :snackbarImage="vasionSnackbarImage"
      :snackbarSubTitle="vasionSnackbarSubTitle"
      :snackbarTitle="vasionSnackbarTitle"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: 'EditSalesForceLookup',
  components: {
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      defaultLookup: false,
      disableSync: true,
      headerColumns: [
        {title: 'Field', sortable: true, type: -1,},
        {title: 'SalesForce Field', sortable: false, type: 2,},
        {title: 'Search', sortable: false, type: 9,},
      ],
      isDirty: false,
      isValid: false,
      loadedPayload: {},
      lookupId: 0,
      lookupName: '',
      salesForceObject: '',
      salesForceObjectAtSync: '',
      salesForceObjectFieldList: [],
      showLeaveDialog: false,
      showVasionSnackbar: false,
      synced: false,
      tableRows: [],
      vasionObject: {},
      vasionSnackbarImage: false,
      vasionSnackbarSubTitle: '',
      vasionSnackbarTitle: '',
    }
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    attributeFormListFields() {
      return this.$store.state.common.indexFields
    },
    displaySalesForceTable() {
      return Boolean(this.vasionObject?.value && this.synced)
    },
    lookupTitle() { return this.lookupId && this.lookupId > 0 ? this.lookupName : 'Untitled SalesForce Lookup' },
  },
  watch: {
    attributeFormListFields: function () {
      this.createTable()
    },
    defaultLookup: function () {
      this.checkIfValid()
    },
    lookupName: function () {
      this.checkIfValid()
    },
    salesForceObject: function () {
      if (this.salesForceObject === '') {
        this.disableSync = true
      } else {
        this.disableSync = false
      }
      this.checkIfValid()
    },
    vasionObject: function () {
      this.$store.dispatch('common/getIndexFieldsForForm', this.vasionObject.value)
      this.checkIfValid()
    },
  },
  async created() {
    this.tableRows = []
    if (!this.attributeFormList || this.attributeFormList.length === 0) {
      await this.$store.dispatch('attributeForm/getForms')
    }
    if (this.$route.params.lookupId && this.$route.params.lookupId > 0) {
      this.loadedPayload = await this.$store.dispatch('fieldMapping/getSalesForceLookupDetails', this.$route.params.lookupId)
      this.defaultLookup = this.loadedPayload.defaultLookup
      this.lookupId = this.loadedPayload.id
      this.lookupName = this.loadedPayload.name
      this.salesForceObject = this.loadedPayload.objectName
      await this.syncLookup()
      this.vasionObject = this.attributeFormList.find(f => f.value === this.loadedPayload.attributeFormID)
      if (this.loadedPayload.attributeFormID > 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', this.loadedPayload.attributeFormID)
      }
    }
  },
  methods: {
    cancel() { this.$router.push({ name: 'DatabaseLookups' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    cellChanged({rowData, columnIndex}) {
      if (columnIndex === 1) {
        rowData.Cells[columnIndex + 1].isDisabled = rowData.Cells[columnIndex].cellData.name ? false : true
        if (rowData.Cells[columnIndex + 1].isDisabled) {
           rowData.Cells[columnIndex + 1].cellData = false
        }
      }
      this.checkIfValid()
      this.markDirty()
    },
    checkIfValid() {
      this.isValid = true

      if (!this.lookupName || this.lookupName === '' || this.lookupName.trim() === '') {
        this.isValid = false
      }

      if (!this.salesForceObject || this.salesForceObject === '' || this.salesForceObject.trim() === '') {
          this.isValid = false
      }

      if (this.salesForceObject !== this.salesForceObjectAtSync){
        this.isValid = false
      }
      
      if (!this.vasionObject || (!typeof this.vasionObject === 'object')
        || (!this.vasionObject.name || this.vasionObject.name === '')
        || (!this.vasionObject.value || this.vasionObject.value === '')) {
          this.isValid = false
      }

      let hasField = false
      let hasSearch = false
      this.tableRows.forEach(element => {
        if (element.Cells[1].cellData?.name){
          hasField = true
        }
        if (element.Cells[2].cellData){
          hasSearch = true
        }
      })
      if (!hasField)
        this.isValid = false
      if (!hasSearch)
        this.isValid = false
    },
    clickedOutsideLeave() { this.routeTo = null },
    createTable() {
      this.tableRows = []
      let SameVasionObjectAsLoad = this.loadedPayload.attributeFormID === this.vasionObject.value
      this.attributeFormListFields.forEach((field) => {
        let obj = {}
        obj.isSelected = false
        obj.id = field.fieldID
        obj.Cells = []
        let selectedSFObject = false
        if (this.loadedPayload && this.loadedPayload?.fieldList) {
          selectedSFObject = this.loadedPayload.fieldList.find(element => element.objectFieldName === field.name)
        }
        obj.Cells[0] = {
          cellData: field.name,
        }
        obj.Cells[1] = {
          cellData: selectedSFObject && SameVasionObjectAsLoad ? this.salesForceObjectFieldList.find(element => element.name === selectedSFObject.salesForceFieldName) : null,
          dropdownValues: this.salesForceObjectFieldList && this.salesForceObjectFieldList.length !== 0 ? this.salesForceObjectFieldList : [{name: '*No fields*', value: -1}],
          canEdit: true,
          fieldName: '',
          subType: 'plain-list'
        }
        obj.Cells[2] = {
          cellData: selectedSFObject && SameVasionObjectAsLoad ? selectedSFObject.useForSearch : false,
          isDisabled: selectedSFObject && SameVasionObjectAsLoad ? false : true,
        }
        // `.push()`` is more reactive, which causes problems when editing a field when that column is used for sorting 
        // On this page, we DO NOT sort on editable columns, and we need the reactivity
        this.tableRows.push(obj)
        // this is less reactive, allowing the user to edit the field, and save, without the order changing - but doesn't update the UI as data is changed
        // this.tableRows[index] = obj
      })
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    markDirty() { this.isDirty = true },
    async save() {
      let payload = {
        id: this.lookupId,
        name: this.lookupName,
        objectName: this.salesForceObject,
        fieldList: [],
        attributeFormID: this.vasionObject.value,
        defaultLookup: this.defaultLookup,
      }

      this.tableRows.forEach(element => {
        let fieldData = {}
        if (element.Cells[1].cellData?.name){
          fieldData.objectFieldName = element.Cells[0].cellData
          fieldData.salesForceFieldName = element.Cells[1].cellData?.name
          fieldData.useForSearch = element.Cells[2].cellData
          payload.fieldList.push(fieldData)
        }
      })

      if (await this.$store.dispatch('fieldMapping/saveSalesForceLookup', payload)) {
        this.vasionSnackbarTitle = 'Success'
        this.vasionSnackbarSubTitle = 'SalesForce Lookup saved successfully'
        this.vasionSnackbarImage = true
        this.showVasionSnackbar = true
        this.isDirty = false
        setTimeout(() => {
          this.$router.push({ name: 'DatabaseLookups' })
        }, 1500)
      } else {
        this.vasionSnackbarTitle = 'Error'
        this.vasionSnackbarSubTitle = 'SalesForce Lookup NOT saved'
        this.vasionSnackbarImage = false
        this.showVasionSnackbar = true
      }
    },
    async syncLookup() {
      this.synced = false
      this.disableSync = true
      this.salesForceObjectAtSync = this.salesForceObject
      const fieldList = await this.$store.dispatch('fieldMapping/getSalesForceFields', this.salesForceObject)
      if (!fieldList) {
        this.vasionSnackbarTitle = 'Error'
        this.vasionSnackbarSubTitle = 'SalesForce Object Sync failed. Check the SalesForce Configuration and the given SalesForce Object. Either may be invalid.'
        this.vasionSnackbarImage = false
        this.showVasionSnackbar = true
        this.disableSync = false
        return
      }
      this.salesForceObjectFieldList = []
      fieldList.forEach((element, index) => {
        this.salesForceObjectFieldList[index] = {"name": element, "value": index}
      }
      )
      this.createTable()

      this.vasionSnackbarTitle = 'Success'
      this.vasionSnackbarSubTitle = 'SalesForce Object Sync succeeded. SalesForce Field(s) are now loaded'
      this.vasionSnackbarImage = true
      this.showVasionSnackbar = true
      this.synced = true
      this.disableSync = false
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #edit-salesforce-lookup {
    #main-section {
      #lookup-details {
        .row {
          width: 100%;
          margin: 13px 0;
          display: flex;

          .row-field {
            margin: 0 14px 5px 0;
          }

          .vasion-button {
            &.add-database-connection-icon-button {
              float: left;
              margin-left: -10px;
              margin-right: 3px;
              margin-top: 20px;
            }
          }
        }
      }

      #lookup-preview {
        #content {
          textarea {
            width: 808px;
            height: 245px;
          }
        }
      }
    }
    
    .comment {
      padding-left: 200px;
    }
    
    ::v-deep .table-header {
      padding: 0px;
    }
    ::v-deep .vasion-html-table-default table td > div {
      padding: 0px;
    }
    .paddingRight {
      padding-right: 16px;
    }
  }
</style>
